<template>
  <div :class="$style['page-body']">
    <slot />
  </div>
</template>

<style lang="scss" module>
.page-body {
  max-width: $xxl;
  min-height: 80vh;
  margin: auto;
}
</style>
